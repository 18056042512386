import React from "react"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeaderMain from "../components/header-main/header-main"

const PageTemplate = ({ pageContext: { frontmatter }, children }) => {
  return (
    <LayoutMain className="page">
      <SeoHeaders title={frontmatter.title} />
      <HeaderMain />
      <section>
        <div className="content-section">
          <div className="page-content">{children}</div>
        </div>
      </section>
    </LayoutMain>
  )
}

export default PageTemplate
